<template>
  <div class="reserve-container container">
    <div class="search">
      <van-search
        class="search-input"
        v-model="searchValue"
        placeholder="逝者姓名、逝者身份证号"
        background="none"
        :clearable="true"
        @search="searchSubmit()"
      />

      <div @click="showPicker = true" class="search-status">
        {{ value }} <van-icon name="arrow-down" />
      </div>
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
          :columns="statusColumn"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
    <van-list
      v-model="allTasks.loading"
      :finished="allTasks.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载"
    >
      <div
        class="reserve-list-item"
        v-for="(item, index) in allTasks.list"
        :key="index"
      >
        <div class="list-title">
          <div class="flex-center">
            <div class="nameSty">{{ item.deadName }}</div>
            <div class="death-card-code">{{ item.deadCardCode }}</div>
          </div>
          <div v-if="item.status == '0'" class="cancel-btn status-todo">
            待处理
          </div>
          <div v-if="item.status == '1'" class="cancel-btn status-ing">
            进行中
          </div>
          <div v-if="item.status == '2'" class="cancel-btn status-ed">
            已完成
          </div>
        </div>
        <div class="list-container">
          <div>外运司机：{{ item.staffName }}</div>
          <div>外运车辆：{{ item.dispatchSendCarNo }}</div>
          <div>外运时间：{{ item.serviceCreateTime }}</div>
          <div>外运地址：{{ item.dispatchSendAddress }}</div>
        </div>
        <div class="list-footer" @click="goDetails(item.applyId)">
          <div>查看详情</div>
          <van-icon class="icon" name="arrow"></van-icon>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ETansportList } from "@/api/remainsPickUp";
export default {
  // 遗体外运
  name: "remainPickUp",
  components: {},
  data() {
    return {
      active: "1",
      searchValue: "",
      value: "全部",
      status: "",
      showPicker: false,
      allTasks: {
        records: [], //全部任务列表
        current: 1, //当前页
        size: 10, //一页多少条
        pages: "", //总页数
        total: "", //总共多少条
        // 以上是请求的数据
        list: [], //本地list
        localTotal: "", //本地total
        loading: false,
        finished: false,
        error: false,
      },

      statusColumn: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "待处理",
          value: "0",
        },
        {
          text: "进行中",
          value: "1",
        },
        {
          text: "已完成",
          value: "2",
        },
      ],
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    setList() {
      // 切换面板 监听事件
    },
    async getList() {
      // 获取列表数据方法
      let deadNameCardCode = this.searchValue;
      this.allTasks.list = []; // 清空数组
      let { data: res } = await ETansportList({
        current: this.allTasks.current,
        size: this.allTasks.size,
        deadNameCardCode,
        status: this.status,
      });
      if (!res || res.records.length === 0) {
        // 判断获取数据条数若等于0
        this.allTasks.list = []; // 清空数组
        this.allTasks.finished = true; // 停止加载
      }
      this.allTasks.pages = res.pages; //总页数
      // 若数据条数不等于0
      this.allTasks.total = res.total; // 给数据条数赋值
      this.allTasks.list.push(...res.records); // 将数据放入list中
      this.allTasks.loading = false; // 加载状态结束
      // 如果list长度大于等于总数据条数,数据全部加载完成
      if (this.allTasks.list.length >= res.total) {
        this.allTasks.finished = true; // 结束加载状态
      }
    },
    async onLoad() {
      // let timer = setTimeout(() => {
      //   // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
      //   this.getList(); // 调用上面方法,请求数据
      //   this.allTasks.page++; // 分页数加一
      //   this.allTasks.finished && clearTimeout(timer); //清除计时器
      // }, 100);

      // 这里不能放在定时器里面，因为异步的问题，在执行clearTimeout前，会多次调用getlist，
      if (!this.allTasks.loading) {
        this.allTasks.loading = true; // 加载状态开始
        await this.getList(); // 调用上面方法,请求数据
        this.allTasks.current++; // 分页数加一
      }
    },

    goDetails(applyId) {
      this.$router.push({
        path: "/remainETansport/details",
        query: {
          applyId: applyId,
        },
      });
    },
    searchSubmit() {
      // this.getList(value);
      // console.log("搜索：",this.searchValue);
      // 判断当前面板1/2 全部任务/我的任务
      this.getList(1);
    },
    onConfirm(value) {
      console.log(value);
      this.value = value.selectedOptions[0].text;
      this.status = value.selectedOptions[0].value;
      this.allTasks.list = [];
      this.allTasks.current = 1;
      this.showPicker = false;
      this.getList();
    },
    goback() {
      // 返回上一级
      this.$router.push("/mine/mine");
    },
  },
};
</script>
<style lang="less" scoped >
@import "../../assets/styles/reserve.less";
.container {
  height: 100vh !important;
  background: rgba(243, 244, 246, 1);
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-input {
    flex: 7;
    border-radius: 10px;
  }
  .search-status {
    font-size: 14px;
    padding-right: 3.5%;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
::v-deep .van-search__content {
  background-color: #fff;
}
::v-deep .van-icon-play {
  transform: rotate(90deg);
  color: #bbbbbb;
}

.death-card-code {
  font-size: 14px;
  font-weight: 500;
  color: #898989;
  margin-left: 10px;
}
.status-todo {
  background: rgba(255, 0, 0, 0.1) !important;
  color: rgba(255, 0, 0, 1) !important;
}
.status-ing {
  background: rgba(255, 154, 88, 0.1) !important;
  color: rgba(255, 154, 88, 1) !important;
}
.status-ed {
  background: rgba(15, 132, 250, 0.1) !important;
  color: rgba(15, 132, 250, 1) !important;
}
.nameSty {
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
